export var name = "d3-flextree";
export var version = "2.0.0";
export var main = "build/d3-flextree.js";
export var module = "index";
export var author = {"name":"Chris Maloney","url":"http://chrismaloney.org"};
export var description = "An tree layout plugin that allows for variable node sizes.";
export var keywords = ["d3","d3-module","layout","tree","hierarchy","d3-hierarchy","plugin","d3-plugin","infovis","visualization","2d"];
export var homepage = "https://github.com/klortho/d3-flextree";
export var license = "WTFPL";
export var repository = {"type":"git","url":"https://github.com/klortho/d3-flextree.git"};
export var scripts = {"clean":"rm -rf _package.js build demo test","build:package":"json2module package.json > _package.js","build:demo":"rollup -c --environment BUILD:demo","build:dev":"rollup -c --environment BUILD:dev","build:prod":"rollup -c --environment BUILD:prod","build:test":"rollup -c --environment BUILD:test","rollup":"rollup -c","build":"npm-run-all build:package rollup","lint":"eslint index.js src","test:main":"node test/bundle.js","test:browser":"node test/browser-tests.js","test":"npm-run-all test:*","prepare":"npm-run-all clean build lint test"};
export var dependencies = {"d3-hierarchy":"^1.1.5"};
export var devDependencies = {"babel-plugin-external-helpers":"^6.22.0","babel-preset-es2015-rollup":"^3.0.0","d3":"^4.13.0","d3-selection-multi":"^1.0.1","eslint":"^4.19.1","jsdom":"^11.6.2","json2module":"0.0.3","npm-run-all":"^4.1.2","package-preamble":"^0.1.0","rollup":"^0.55.3","rollup-plugin-babel":"^2.7.1","rollup-plugin-commonjs":"^8.0.2","rollup-plugin-copy":"^0.2.3","rollup-plugin-node-resolve":"^3.0.2","rollup-plugin-uglify":"^3.0.0","uglify-es":"^3.3.9"};
